import React from 'react';
import { Box, Flex, Text, Link as ChakraLink } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <Box bg="teal.500" color="white" p={4} mb={4}>
      <Flex justifyContent="space-between" alignItems="center" maxW="1200px" mx="auto">
        <Text fontSize="lg" fontWeight="bold">
          嘻乐笑馆
        </Text>
        <Flex gap={4}>
          <ChakraLink as={Link} to="/" _hover={{ textDecoration: 'underline' }} fontSize="md">
            喂食记录
          </ChakraLink>
          <ChakraLink as={Link} to="/visualization" _hover={{ textDecoration: 'underline' }} fontSize="md">
            可视化分析
          </ChakraLink>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Header;