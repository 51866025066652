import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Select, Stack, Textarea, Checkbox, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react';

function EditFeedingForm({ feeding, isOpen, onClose, onSubmit }) {
  const [formData, setFormData] = useState({
    formula: feeding.formula,
    poop: feeding.poop,
    baby_food: feeding.baby_food,
    notes: feeding.notes || '',
    d3: feeding.d3 || false,
    fe: feeding.fe || false,
    probiotic: feeding.probiotic || false,
    sime: feeding.sime || false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(feeding.fid, formData);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>编辑喂养记录</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <FormControl isRequired>
                <FormLabel>奶粉量 (ml)</FormLabel>
                <Input
                  type="number"
                  name="formula"
                  value={formData.formula}
                  onChange={handleChange}
                  placeholder="输入奶粉量"
                />
              </FormControl>

              <FormControl>
                <FormLabel>便便次数</FormLabel>
                <Select name="poop" value={formData.poop} onChange={handleChange}>
                  <option value="">选择便便次数</option>
                  <option value="0">0 次</option>
                  <option value="1">1 次</option>
                  <option value="2">2 次</option>
                  <option value="3">3 次</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>辅食量 (ml)</FormLabel>
                <Input
                  type="number"
                  name="baby_food"
                  value={formData.baby_food}
                  onChange={handleChange}
                  placeholder="输入辅食量"
                />
              </FormControl>

              <FormControl>
                <FormLabel>备注</FormLabel>
                <Textarea
                  name="notes"
                  value={formData.notes}
                  onChange={handleChange}
                  placeholder="输入备注"
                />
              </FormControl>

              <FormControl>
                <Checkbox name="d3" isChecked={formData.d3} onChange={handleChange}>
                  D3 补充
                </Checkbox>
              </FormControl>

              <FormControl>
                <Checkbox name="fe" isChecked={formData.fe} onChange={handleChange}>
                  铁补充 (Fe)
                </Checkbox>
              </FormControl>

              <FormControl>
                <Checkbox name="probiotic" isChecked={formData.probiotic} onChange={handleChange}>
                  益生菌
                </Checkbox>
              </FormControl>

              <FormControl>
                <Checkbox name="sime" isChecked={formData.sime} onChange={handleChange}>
                  防胀气 (Sime)
                </Checkbox>
              </FormControl>
            </Stack>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="green" mr={3} onClick={handleSubmit}>
            保存更改
          </Button>
          <Button variant="ghost" onClick={onClose}>
            取消
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default EditFeedingForm;
