import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import axios from 'axios';

// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

// Function to format date as YYYYMMDD
const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
};

const DailyFeedingVisualization = () => {
    const [leleData, setLeleData] = useState(null);
    const [xixiData, setXixiData] = useState(null);
    const todayDate = formatDate(new Date()); // Use local time

    useEffect(() => {
        // Fetch data for both babies
        const fetchData = async () => {
            try {
                const startDate = '20230913'; // Hardcoded start date for now

                // Fetch data for Lele
                const leleResponse = await axios.get(
                    `https://5krlj5d2d8.execute-api.us-east-1.amazonaws.com/prod/feedings/daily`,
                    {
                        params: {
                            start: startDate,
                            end: todayDate,
                            baby: 'lele',
                        },
                    }
                );
                setLeleData(leleResponse.data.items);

                // Fetch data for Xixi
                const xixiResponse = await axios.get(
                    `https://5krlj5d2d8.execute-api.us-east-1.amazonaws.com/prod/feedings/daily`,
                    {
                        params: {
                            start: startDate,
                            end: todayDate,
                            baby: 'xixi',
                        },
                    }
                );
                setXixiData(xixiResponse.data.items);
            } catch (error) {
                console.error('Error fetching feeding data:', error);
            }
        };

        fetchData();
    }, []);

    if (!leleData || !xixiData) {
        return <p>Loading data...</p>;
    }

    // Function to prepare chart data
    const prepareChartData = (data) => {
        const labels = data.map((item) => item.sk); // Dates
        const formulaData = data.map((item) => item.formula); // Formula amounts
        const babyFoodData = data.map((item) => item.baby_food); // Baby food amounts

        return {
            labels,
            datasets: [
                {
                    label: '奶粉 (ml)',
                    data: formulaData,
                    borderColor: 'rgba(75,192,192,1)',
                    backgroundColor: 'rgba(75,192,192,0.2)',
                    tension: 0.4,
                },
                {
                    label: '辅食 (g)',
                    data: babyFoodData,
                    borderColor: 'rgba(255,99,132,1)',
                    backgroundColor: 'rgba(255,99,132,0.2)',
                    tension: 0.4,
                },
            ],
        };
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
        },
    };

    return (
        <div style={{ padding: '20px' }}>

            {/* Chart for Lele */}
            <div style={{ marginBottom: '40px' }}>
                <h3>乐乐</h3>
                <Line data={prepareChartData(leleData)} options={chartOptions} />
            </div>

            {/* Chart for Xixi */}
            <div>
                <h3>嘻嘻</h3>
                <Line data={prepareChartData(xixiData)} options={chartOptions} />
            </div>
        </div>
    );
};

export default DailyFeedingVisualization;
