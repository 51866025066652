import React from 'react';
import { ChakraProvider, Box } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header'; 
import FeedingsToday from './FeedingsToday';  // Import the FeedingsToday component
import DailyFeedingVisualization from './DailyFeedingVisualization';  // Import the visualization component

function App() {
  return (
    <ChakraProvider>
      <Router>
        <Header /> {/* Persistent Banner */}
        <Box maxW="1200px" mx="auto" p={4}>
          <Routes>
            <Route path="/" element={<FeedingsToday />} />
            <Route path="/visualization" element={<DailyFeedingVisualization />} />
          </Routes>
        </Box>
      </Router>
    </ChakraProvider>
  );
}

export default App;
